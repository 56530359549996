@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
body {
  @apply font-roboto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 4px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background-image: linear-gradient(45deg, #74b0ffbb, #fff);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(255, 255, 255);
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:active {
  background: rgb(255, 255, 255);
  border-radius: 8px;
}

[type="date"] {
  background: #fff url() 97% 50% no-repeat;
  height: 38px !important;
}
[type="date"]::-webkit-inner-spin-button {
  display: none;
}
[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
  position: absolute;
  width: 99%;
  z-index: 0;
}

[type="month"] {
  background: #fff url() 97% 50% no-repeat;
  height: 38px !important;
}
[type="month"]::-webkit-inner-spin-button {
  display: none;
}
[type="month"]::-webkit-calendar-picker-indicator {
  opacity: 0;
  position: absolute;
  width: 100%;
  z-index: 0;
}

/* custom styles */

label {
  display: block;
}

input[type="date"] {
  border: 1px solid #c4c4c4;

  background-color: #fff;
  padding: 3px 5px;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1);
  width: 250px;
  height: 30px;
}
input[type="date"]:focus {
  outline: none;
  border-color: #007bff; /* Change border color on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Add a subtle shadow on focus */
  max-width: 250px;
}

input[type="month"] {
  border: 1px solid #c4c4c4;

  background-color: #fff;
  padding: 3px 5px;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1);
  width: 250px;
  height: 30px;
}

input[type="file"] {
  border: 1px solid #c4c4c4;
  background-color: #fff;
  padding: 0 0;

  width: 250px;
  height: 32px;
}

input[type="file"]::-webkit-file-upload-button {
  height: 30px;
  background-color: #eceeef;
  border: none;
  border-right: 1px solid #eceeef;
}

.react-datepicker__input-container input {
  height: 38px !important;
}

.react-datepicker {
  font-size: 1.2rem !important;
}

.login {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(6.5px);
  -webkit-backdrop-filter: blur(6.5px);
  border-radius: 10px;
}

.main_login {
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(./water.jpeg);
  background-size: cover;
  background-position: center center;
  opacity: 0.9;
}

.logo_img {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  display: block;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
}

.login_form_div {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 20px;
  height: auto;
  min-width: 280px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 2.5rem;
}

.input-field {
  position: relative;
  border-bottom: 2px solid #ccc;
  margin: 12px 0;
}

.input-field label {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  color: #fff;
  font-size: 14px;
  pointer-events: none;
  transition: 0.15s ease;
}

.input-field input {
  width: 100%;
  height: 40px;
  background: transparent;
  border: none;
  outline: none;
  font-size: 16px;
  color: #fff;
}

.input-field input:focus ~ label,
.input-field input:valid ~ label {
  font-size: 0.9rem;
  top: 10px;
  transform: translateY(-120%);
}

.forget {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 25px 0 35px 0;
  color: #fff;
}

#remember {
  accent-color: #fff;
}

.forget label {
  display: flex;
  align-items: center;
}

.forget label p {
  margin-left: 8px;
}

.wrapper a {
  color: #efefef;
  text-decoration: none;
}

.wrapper a:hover {
  text-decoration: underline;
}

.login_btn {
  background: #fff;
  color: #000;
  font-weight: 600;
  border: none;
  padding: 8px 20px;
  cursor: pointer;
  border-radius: 3px;
  font-size: 16px;
  border: 2px solid transparent;
  transition: 0.3s ease;
  margin-top: 12px;
}

/* button:hover {
  color: #fff;
  border-color: #fff;
  background: rgba(255, 255, 255, 0.15);
} */

.register {
  text-align: center;
  margin-top: 30px;
  color: #fff;
}

.footer_text {
  color: #ffffff9f;
  position: absolute;
  bottom: 0;
  left: 50%;
  padding: 10px 0;
  font-size: 0.6rem;
  transform: translateX(-50%);
}

/* a {
  color: #ffffff9f;
} */

.error {
  color: red;
}
